import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

import { ReactComponent as Logo } from "./logo.svg";
import "./App.css";
import ArgumentBrowser from "./ArgumentBrowser";

function App({ defaultId }) {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Link to="/">
            <Logo className="App-logo" title="Home" />
          </Link>
        </header>
        <Route path="/:pathInfo+" component={ArgumentBrowser} />
        <Route exact path="/">
          <Redirect to={"/" + defaultId} />
        </Route>
      </div>
    </Router>
  );
}

export default App;
