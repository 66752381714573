import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/hind-vadodara/400.css";
import "@fontsource/hind-vadodara/700.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

async function start() {
  const rootId =
    window.location.pathname.split("/")[1] ||
    window.location.search.substring(1) ||
    "Q105";
  const response = await fetch(
    `https://api.tietokide.fi/?item=${encodeURIComponent(rootId)}`
  );
  const sparql_result = await response.json();
  window.elements = sparql_result;

  if (!window.location.pathname.split("/")[1]) {
    window.history.replaceState(null, "", "/" + rootId);
  }

  ReactDOM.render(<App defaultId={rootId} />, document.getElementById("root"));
}

window.oncontextmenu = function (event) {
  event.preventDefault();
  event.stopPropagation();
  return false;
};

start();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
