import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import { ReactComponent as CancelIcon } from "./close-button.svg";

import { ReactComponent as SubmitIcon } from "./send-bold.svg";
import "./ArgumentEntry.css";

const properties = {
  attack: "P14",
  defense: "P15",
  comment: "P13",
};

const maxLength = 250;

async function postEdit(name, property, object) {
  const response = await fetch("https://api.tietokide.fi/", {
    method: "POST",
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
    },
    body: `name=${encodeURIComponent(
      name
    )}&property=${property}&object=${object}`,
  });
  const result = await response.json();
  return result;
}

function ArgumentEntry({
  subject,
  style,
  history,
  blindMode,
  onFocus,
  onBlur,
}) {
  function unblind() {
    history.replace(history.location.pathname, { unblind: true });
  }
  function onTitleChange(event) {
    const { value } = event.target;
    setTitle(value);
  }
  async function onSubmit() {
    if (!type || !title || pending || title.length > maxLength) {
      return;
    }

    let result;
    setPending(true);
    try {
      result = await postEdit(title, properties[type], subject);
    } catch (error) {
      alert(error);
    }
    setPending(false);
    if (!result || !result.entity || !result.entity.id) {
      return;
    }

    const id = result.entity.id;
    window.elements.push({
      o: {
        value: id,
        label: title,
      },
      s: subject,
      p: properties[type],
    });
    if (blindMode) {
      unblind();
    } else {
      history.push(
        history.location.pathname.replace(/\/kysymys/, "") + "/" + id
      );
    }
    setType("");
    setTitle("");
  }
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [pending, setPending] = useState(false);
  const typeClass = type || "";
  const tooLongClass = title.length > maxLength ? "error" : "";
  const defenseSelectedClass = type === "defense" ? "selected" : "";
  const attackSelectedClass = type === "attack" ? "selected" : "";
  const commentSelectedClass = type === "comment" ? "selected" : "";
  const counterStyle = {
    visibility: title.length ? "visible" : "hidden",
  };
  return (
    <div>
      <div className={"Argument ArgumentEntry " + typeClass} style={style}>
        <div className="thread-curve"></div>
        <div className="thread-point"></div>
        <div style={{ width: "100%" }}>
          <button
            onClick={unblind}
            style={{
              ...style,
              position: "absolute",
              left: "-0.4em",
              marginTop: "-1.35em",
              width: 24,
              height: 24,
              visibility: blindMode ? "visible" : "hidden",
            }}
          >
            <CancelIcon />
          </button>
          <textarea
            onChange={onTitleChange}
            value={title}
            placeholder="…"
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <div
            className={"ArgumentEntry-counter " + tooLongClass}
            style={counterStyle}
          >
            {title.length} / {maxLength}
          </div>
        </div>
        <button
          onClick={onSubmit}
          disabled={!type || !title || pending || title.length > maxLength}
          className="ArgumentEntry-submit"
        >
          <SubmitIcon title="Submit" />
        </button>
      </div>
      <div className="ArgumentEntry-types">
        <button
          onClick={() => setType("defense")}
          className={"ArgumentEntry-type defense " + defenseSelectedClass}
        >
          +
        </button>
        <button
          onClick={() => setType("attack")}
          className={"ArgumentEntry-type attack " + attackSelectedClass}
        >
          -
        </button>
        <button
          onClick={() => setType("comment")}
          className={"ArgumentEntry-type comment " + commentSelectedClass}
        >
          ?
        </button>
      </div>
    </div>
  );
}

export default withRouter(ArgumentEntry);
