import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./ArgumentBrowser.css";
import ArgumentEntry from "./ArgumentEntry";

const properties = {
  P14: "attack",
  P15: "defense",
  P13: "comment",
  Q26: "attack",
  Q28: "defense",
  Q29: "comment",
};

const propertyOrder = {
  P14: 1,
  P15: 2,
  P13: 3,
};

function ArgumentBrowser({ match, location }) {
  const pathIdsRaw = match.params.pathInfo.split("/");
  const blindRequested = pathIdsRaw[1] === "kysymys";
  const pathIds = !blindRequested
    ? pathIdsRaw
    : pathIdsRaw.slice(0, 1).concat(pathIdsRaw.slice(2));
  const focusId = pathIds[pathIds.length - 1];
  const focus = window.elements.filter((value) => value.o.value === focusId)[0];
  // const parentId = focus.s;
  const children = window.elements.filter((value) => value.s === focusId);
  const uniqueChildren = [
    ...new Map(children.map((value) => [value.o.value, value])).values(),
  ];

  const headingType =
    properties[focus.argtype] || properties[focus.p] || "statement";

  const pathItems = pathIds.map((id, index) => {
    const item = window.elements.filter((value) => value.o.value === id)[0];
    const argType =
      properties[item.argtype] || properties[item.p] || "statement";
    let url = "/" + pathIds.slice(0, index + 1).join("/");
    return (
      <Link key={id} to={url}>
        <div
          className={
            `Argument ${argType} ` +
            (index === pathIds.length - 1 ? "selected" : "")
          }
          style={{ marginLeft: `${8 + 24}px` }}
        >
          <div className="thread-point"></div>
          <div className="chevron"></div>
          <div className="title">{item.o.label}</div>
        </div>
      </Link>
    );
  });

  const items = uniqueChildren
    .sort((x, y) => propertyOrder[x.p] - propertyOrder[y.p])
    .map((value, index) => {
      const argType =
        properties[value.argtype] || properties[value.p] || "statement";
      const obsoleteClass = ""; // XXX disabled for now: value.oStatus === "Q98" ? "obsolete" : "";
      return (
        <Link
          key={value.o.value}
          to={"/" + pathIds.join("/") + "/" + value.o.value}
        >
          <div className="thread-item">
            <div
              className={`Argument ${argType} ${obsoleteClass}`}
              style={{ marginLeft: `${8 + 24 + 48}px` }}
            >
              <div className="thread-curve"></div>
              <div className="thread-point"></div>
              <div className="title">{value.o.label}</div>
              <div className="chevron"></div>
            </div>
          </div>
        </Link>
      );
    });

  const [composing, setComposing] = useState(false);

  const blindMode =
    blindRequested && !(location.state && location.state.unblind);
  const argumentsVisible = !composing && !blindMode;
  const breadcrumbsVisible = !blindMode;

  return (
    <div className="Argument-browser">
      <div className="thread-line">
        <div className="Argument-breadcrumbs">
          {breadcrumbsVisible ? pathItems : pathItems[pathItems.length - 1]}
        </div>
        <div className={`Argument ${headingType} Argument-heading`}>
          <div className="title">{focus.o.label}</div>
        </div>
        <div className="Argument-list">{argumentsVisible && items}</div>
      </div>
      <div>
        <ArgumentEntry
          subject={focusId}
          style={{ marginLeft: `${32 + 2 + 8 + 24 + 48}px` }}
          blindMode={blindMode}
          onFocus={() => setComposing(true)}
          onBlur={() => setComposing(false)}
        />
      </div>
    </div>
  );
}

export default ArgumentBrowser;
